<template>
  <section class="vocabulary">
    <h1 id="page-title">
      {{ $t('vocabulary') }}
    </h1>
    <b-row class="row-wide">
      <b-col lg="auto">
        <div class="left-sideblock">
          <AddWord @selected="addWord" class="mb-3"/>
          <div class="spacecard md mob-scroll">
            <a class="d-md-block d-inline-block pointer menu-point"
               :class="{active: mode == m}"
               :key="'mode-'+m"
               v-for="m in modes"
               @click="mode = m">
              {{ $t(m) }}
            </a>
            <hr/>
            <a class="d-md-block d-inline-block pointer menu-point" 
               @click="mode = 'cards'" 
               :class="{active: mode == 'cards'}">
              {{ $t('word_cards') }}
            </a>
          </div>
        </div>
      </b-col>
      <b-col lg class="mt-md-0 mt-3">
        <div class="tabs-navigator lg d-inline-block">
          <a :class="{ active: learned == '0' }" @click="learned = '0'" >{{ $t('learning') }}</a>
          <a :class="{ active: learned == '1' }" @click="learned = '1'">{{ $t('learned') }}</a>
        </div>
        <div class="spacecard min-h-card" v-if="loaded">
          <div v-if="words.length > 0 && (learned == '0' || mode === 'cards')" class="spacecard gray sm mb-md-0 mb-2">
            <b-row class="align-items-center">
              <b-col>
                {{ learned == '1' && mode === 'cards' ? $t('repeating') : $t('words_training') }}
              </b-col>
              <b-col cols="auto">
                <div  v-if="mode !== 'cards'">
                  <router-link class="btn-themed" :to="{ name: 'VocabularyTraining', query: { mode: mode } }">
                    {{ $t('start_training') }}
                  </router-link>
                </div>
                <div v-if="mode === 'cards'">
                  <span class="small text-muted mr-3 d-inline-block align-middle">{{ $t('amount_of_cards') }}</span>
                  <a class="d-inline-block align-middle p-2 pointer" @click="cardsToStudy > 3 ? cardsToStudy-- : ''">
                    <MinusSvg class="svg-darkgray"/>
                  </a>
                  <span class="qty-card">{{ cardsToStudy }}</span>
                  <a class="d-inline-block align-middle p-2 pointer" @click="plusCard">
                    <PlusSvg class="svg-darkgray"/>
                  </a>
                  <router-link class="btn-themed ml-3" :to="{ name: 'VocabularyTraining', query: { mode: mode, amount: cardsToStudy, learned: learned, page: page } }">
                    {{ $t('start_training') }}
                  </router-link>
                </div>
              </b-col>
            </b-row>
          </div>
          <template v-for="(w, wind) in words">
            <WordTeaser v-if="mode == 'all' || mode == w.type"
                        :key="'word-'+w.id"
                        :word="w">
            </WordTeaser>
            <WordCardTeaserRow :word="w.word_card"
                               v-if="mode == 'cards' && wind < cardsToStudy"
                               :key="'word-card-'+w.id"
                               :cards="[]"
                               view="student"/>
          </template>
          <div v-if="learned && words.length == 0 && loaded" class="text-center pt-5">{{ $t('nothing_found') }}</div>
        </div>
        <Paginator :paginator="paginator"/>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import AddWord from '@/views/parts/class/AddWord'
import WordTeaser from '@/views/teasers/WordTeaser'
import { VocabularyService } from '@/services/api.service'
import Paginator from "@/views/parts/general/Paginator"
import WordCardTeaserRow from "../../../teasers/WordCardTeaserRow"
import PlusSvg from "@/assets/svg/plus2.svg"
import MinusSvg from "@/assets/svg/minus.svg"

export default {
  components: {
    WordCardTeaserRow,
    AddWord,
    WordTeaser,
    Paginator,
    PlusSvg,
    MinusSvg
  },
  data() {
    return {
      loaded: false,
      words: [],
      modes: ['all', 'mine', 'material'],
      mode: 'all',
      learned: this.$route.query.learned ? this.$route.query.learned : "0",
      page: this.$route.query.page ? this.$route.query.page : 1,
      paginator: null,
      cardsToStudy: 9
    }
  },
  mounted() {
    this.getWords()
  },
  metaInfo() {
    return {
      title: this.$t('vocabulary'),
    }
  },
  watch: {
    mode() {
      if(this.page != 1) {
        this.$router.push({ path: this.$route.path, query: { page: 1, learned: this.learned }})
      } else {
        this.getWords()
      }
    },
    learned(val) {
      this.$router.push({ path: this.$route.path, query: { page: 1, learned: val }})
      this.getWords()
    },
    cardsToStudy() {
      if(!this.paginator) return
      this.getWords(true)
    },
    $route() {
      if(this.$route.query.page != this.page) {
        this.page = this.$route.query.page
        this.getWords(true)
      }
    }
  },
  methods: {
    addWord(word) {
      let params = {
        word: word.word,
        translation: word.translation_ru
      }
      VocabularyService.addWordToStudentStudy(params)
    },
    async getWords(loaded = false) {
      this.loaded = loaded
      try {
        let res;
        if(this.mode === 'cards') {
          res = await VocabularyService.getStudentCards(this.mode, this.learned, this.page, this.cardsToStudy)
        } else {
          res = await VocabularyService.getStudentWords(this.mode, this.learned, this.page)
        }
        this.words = res.data.data.data
        if(!loaded) this.cardsToStudy = Math.min(this.words.length, 9) 
        this.paginator = res.data.data
      } catch (e) { console.log(e) }
      this.loaded = true
    },
    plusCard() {
      let limit = this.paginator ? Math.min(this.paginator.total, 9) : this.words.length
      if(this.cardsToStudy < limit) {
        this.cardsToStudy++
        if(this.page != 1) {
          this.page = 1
          this.$router.push({ path: this.$route.path, query: { page: 1 }})
        }
        
      }
      // if(this.cardsToStudy > this.words.length) {
      //   this.getWords(true)
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.menu-point {
  border-radius: 13px;
  padding: 12px 16px;
  &.active, &:hover {
    background: $almost-white;
    text-decoration: none;
  }
}
.qty-card {
  background: #FFFFFF;
  border: 1px solid #DADFEB;
  border-radius: 11px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media(max-width: 768px) {
  .menu-point {
    padding: 10px 14px;
  }
}
</style>


