import { DictionaryService } from "../services/api.service"

export const generateAudio = async (text, wordId = null, slideId = null, questionUrl = null, index = null, audio_config = {}) => {
    const res = await DictionaryService.generateAudio(text, wordId, slideId, questionUrl, index, audio_config)
    const audio = new Audio('data:audio/mp3;base64,'+res.data.data.base64)
    audio.play()
    return res.data.data.audioUrl

    // let msg = new SpeechSynthesisUtterance()
    // let voices = window.speechSynthesis.getVoices()
    // msg.voice = voices[7]
    // msg.voiceURI = "native"
    // msg.volume = 1
    // msg.rate = 1
    // msg.pitch = 0.8
    // msg.text = text
    // msg.lang = 'en-GB'
    // speechSynthesis.speak(msg)

}
