<template>
  <div v-if="paginator && paginator.last_page > 1" class="paginator" >
    <button class="pag-item pag-link" :disabled="paginator.current_page < 2" @click="paginatorAction('previous')">«</button>
    <button class="pag-item pag-link" @click="paginatorAction('pagenum',1)" v-if="paginator.current_page > 1">1</button>
    <button class="pag-item" v-if="(paginator.current_page - 1) > 2">...</button>
    <button class="pag-item pag-link" @click="paginatorAction('pagenum',paginator.current_page - 1)" v-if="(paginator.current_page - 1) > 1">{{ paginator.current_page - 1 }}</button>
    <button class="pag-item active">{{ paginator.current_page }}</button>
    <button class="pag-item pag-link" @click="paginatorAction('pagenum',paginator.current_page + 1)" v-if="(paginator.current_page + 1) < paginator.last_page">{{ paginator.current_page + 1 }}</button>
    <button class="pag-item" v-if="(paginator.current_page + 2) < paginator.last_page">...</button>
    <button class="pag-item pag-link" @click="paginatorAction('pagenum',paginator.last_page)" v-if="paginator.last_page > paginator.current_page">{{ paginator.last_page }}</button>
    <button class="pag-item pag-link" :disabled="paginator.current_page >= paginator.last_page" @click="paginatorAction('next')">»</button>
  </div>
</template>

<script>
export default {
  props: {
    paginator: {}
  },
  data() {
    return {
      page: this.$route.query.page ? this.$route.query.page : 1,
    }
  },
  methods: {
    paginatorAction(action, pagenum) {
      if(action === 'next') {
        this.page++
      }
      if(action === 'previous') {
        this.page--
      }
      if(action === 'pagenum' && pagenum) {
        this.page = pagenum
      }
      this.$router.push({ path: this.$route.path, query: { page: this.page }})
    }
  }
}
</script>

<style scoped>

</style>
